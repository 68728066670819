import React from 'react';

import './assets/sass/App.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';

import logopic from "./assets/images/logo.png";

import Image from 'react-bootstrap/Image';

import { ShowcaseAccordian } from './components'




function App() {
  return (
    <div className="w-75 m-auto">

      <header className="app-header m-auto py-0">
        <Image src={logopic} fluid />
        <h3 className="p-5 fluid">Cherry Lane Consulting</h3>
      </header>

      <Card className="p-3 fluid my-3 d-flex text-justify">
        <Card.Body>
    <p>Cherry Lane Consulting is proud to have served a wide range of private and goverment clients for some exciting projects over the last ten years, with deliverables including configuration-managed backend APIs and full stack solutions.</p>
    <p>
    With experience of most database and web frameworks we are the perfect choice for your data-driven website needs. Furthermore our frontend exerience spans all the layers you would expect including pure JS, jquery and or course the modern and powerful ReactJS.
    </p>
        </Card.Body>
      </Card>

      <ShowcaseAccordian />

    </div>
  );
}

export default App;
