import * as React from "react";
import {createContext} from "react";

import pythonpic from "../assets/images/carousel/python.png";

import debianpic from "../assets/images/carousel/debian.png";
import redhatpic from "../assets/images/carousel/redhat.png";
import ubuntupic from "../assets/images/carousel/ubuntu.svg";
import raspianpic from "../assets/images/carousel/raspian.jpg";

import puppetpic from "../assets/images/carousel/puppet.png";
import ansiblepic from "../assets/images/carousel/ansible.png";
import chefpic from "../assets/images/carousel/chef.png";
import saltstackpic from "../assets/images/carousel/saltstack.png";

import mojpic from "../assets/images/carousel/govuk.png";
import hmctspic from "../assets/images/carousel/govuk.png";
import kloeknerpic from "../assets/images/carousel/kloeckner.webp";
import vertupic from "../assets/images/carousel/vertu.png";
import fabricwwpic from "../assets/images/carousel/fabricww.jpg";

import sqlapic from "../assets/images/carousel/sqla.png";
import djangopic from "../assets/images/carousel/django.png";
import postgrespic from "../assets/images/carousel/postgres.png";
import oraclepic from "../assets/images/carousel/oracle.png";

import djangoormpic from "../assets/images/carousel/djangoorm.jpg";
import fastapipic from "../assets/images/carousel/fastapi.png";
import tornadopic from "../assets/images/carousel/tornado.png";
import flaskpic from "../assets/images/carousel/flask.png";

import githubpic from "../assets/images/carousel/github.png";
import awspic from "../assets/images/carousel/aws.png";
import googlepic from "../assets/images/carousel/google.png";

import hydrapic from "../assets/images/carousel/nopic.svg";
import blipthingpic from "../assets/images/carousel/nopic.svg";
import cryptobotpic from "../assets/images/carousel/nopic.svg";

import rclarkpic from "../assets/images/carousel/rclark.jpg";

import nopic from "../assets/images/carousel/nopic.svg";



export const PeopleContext = {
  "rclark": {
    "name": "Richard Clark",
    "images": [rclarkpic],
    "alt": "",
    "email": "rclark@cherrylaneconsulting.com",
    "description1": "Software, Physics, Electronics",
    "description2": "",
    "previousemployment": {
      "vertu": {
        "name": "Vertu",
        "images": [vertupic],
        "alt": "",
        "description1": "Vertu are a unique, premium player in the mobile phone market and they are proud to manufacture their products in the UK. I was part of the team to create a greenfield micro-services backend using Python, postgres and our own Python micro-framework with extensive configuration management recipies using Puppet.",
        "description2": "Working with Vertu also gave me the opportunity to apply Puppet configuration management to extreme levels all the way up the stack, from PXE boot and DHCP through to building the C++ application and crytographic hardware.",
      },
    }
  }
}

export const PersonalProjectsContext = {
  "blipthing": {
    "name": "Parcel tracking",
    "images": [blipthingpic],
    "alt": "",
    "description1": "This is a Django app with a focus on multi-lingual, global calandering system for tracking parcels, vehicles and containers.",
    "description2": "Developed for a client in the shipping industry as a demonstration of what is possible and to explore the latest Django features."
  },
  "hydra": {
    "name": "Electronics control",
    "images": [hydrapic],
    "alt": "",
    "description1": "Distributed electronics control system",
    "description2": "A number of raspberry pi boards, each with a different hardware configurations, work together via a central node. Browser clients including touchscreen displays allow for any virtual panel of components to be accessed. Low power, versatile and continuously configurable, this system is more than the sum of its parts.",
  },
  "cryptobot": {
    "name": "Crypto-currency trading bot",
    "images": [cryptobotpic],
    "alt": "",
    "description1": "Developed to encourage beginners to learn python, this bot can take data from a number of feeds, conbine them into a consistent data stream, apply that data stream to an an arbitrary number of concurrent strategies, and make trades on a number of exchanges.",
  },

}

export const ClientsContext = {
  "moj": {
    "name": "Ministry of Justice",
    "images": [mojpic],
    "alt": "",
    "description1": "The MoJ was among the first ministries to embrace the government's digital-first programme. Saltstack was the choice for configuration management of virtual private servers that delivered the services.",
  },
  "kloekner": {
    "name": "Kloekner Metals",
    "images": [kloeknerpic],
    "alt": "",
    "description1": "This client is determined to be the best in their field at handling complex purchase orders automatically and deployed machine learning very effectively to that end. They have a dedication to agile methods with highly motivated engineers, delivering value by easing the workloads of the sales team.",
  },
  "hmcts": {
    "name": "HM Courts and Tribunal Service",
    "images": [hmctspic],
    "alt": "",
    "description1": "Applying Agile devops methods to web application development inside HMCTS. Integrating with legacy services and creating a refined user experience with new services.",
  },
  "fabricww": {
    "name": "Fabric WW Ltd",
    "images": [fabricwwpic],
    "alt": "",
    "description1": "This advertising agency had a prototype for their new, flagship product which needed some performance and scaling issues addressing. Adding concurrency to the cloud-based back-end storage requests allowed it to scale massively.",
    "description2": "We also delivered a new, multi-tenant advertising platform. It allowed many tenants to manage a number of ads and target the most appropriate ad at the user based on known demographics as well as the occurence of defined events within a rolling timeframe.",
  },
}
export const ProfessionalProjectsContext = {
  "upgrade": {
    "name": "Python, Django version updates",
    "images": [nopic],
    "alt": "",
    "description1": "",
  },
  "greenfield": {
    "name": "Greenfield personal projects",
    "images": [nopic],
    "alt": "",
    "description1": "Keeping software up to date is important and yet often overlooked. Some clients have engaged us specifically to bring their applications up to date, while for some, especially that had not made a build for a long time, it was necessary to update their package versions in order to get a working build. Time moves on and it is generally better to build in a way to take small steps as you go into your CI pipeline, rather than planning large updates..",
  },
  "rearch": {
    "name": "Re-architecturing Python applications",
    "images": [nopic],
    "alt": "",
    "description1": "",
  },
}

export const ToolsContext = {
  "python": {
    "name": "Python Development",
    "images": [pythonpic],
    "alt": "Python Development",
    "description1": "Python is very flexible in terms of the types of applications it can create. Whether it is a web application server, a desktop application, a background server service or a quick and dirty script, it can do it all.",
  },
  "dbapis": {
    "name": "Database tools",
    "images": [sqlapic, postgrespic, oraclepic, djangoormpic],
    "alt": "Database abstractions",
    "description1": "I have had the pleasure of working with many database APIs, including Django ... and my favourite is SQLAlchemt given its flexibilty and portability. For one project of two parts I used Django to maintain the database schema and used SQLAlchemy to read the schema reflectively.",
  },
  "linux": {
    "name": "Linux Administration",
    "images": [redhatpic, debianpic, ubuntupic, raspianpic],
    "alt": "Linux Administration",
    "description1": "RedHat may be the commercial leader in the space but I think it's important to recognise the values of the Debian project, especially now in its 30th year, an the good it has done through maintaining the distribution.",
  },
  "config": {
    "name": "Configuration Management",
    "images": [puppetpic, ansiblepic, chefpic, saltstackpic],
    "alt": "Configuration Management",
    "description1": "Configuration management ensures servers are configured in terms of packages, configuration files, running services and much more. By writing well-crafted receipes you can make code reuse at the heart of your infrastructure as code.",
  },
  "webframeworks": {
    "name": "Web frameworks",
    "images": [djangopic, fastapipic, tornadopic, flaskpic],
    "alt": "",
    "description1": "Django, Flask and FastAPI all have their own strengths and weaknesses despite there being a lot of overlap in their features. Choosing the right one might be as much about maintainability by the future developers rather.",
  },
  "cloud": {
    "name": "Cloud computing and Orchestration",
    "images": [githubpic, awspic, googlepic, googlepic],
    "alt": "",
    "description1": "While the current trend for Serverless Teraform is strong, I think there is a lot to be said for VPS and configuration management tools such as puppet, saltstack, chef and ansible.",
  },
}
