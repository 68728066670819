import Accordion from 'react-bootstrap/Accordion'
import { useContext } from 'react';

import { CarouselComponent } from './Carousel';
import { PeopleContext, ToolsContext, ClientsContext, ProfessionalProjectsContext, PersonalProjectsContext } from './Contexts';


export function ShowcaseAccordian() {
    return (
        <div>
          <Accordion defaultActiveKey="0" >

            <Accordion.Item eventKey="0">
              <Accordion.Header>Software Tools</Accordion.Header>
              <Accordion.Body>
                  <CarouselComponent data={ToolsContext} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Previous Clients</Accordion.Header>
              <Accordion.Body>
                <CarouselComponent data={ClientsContext} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Commercial Projects</Accordion.Header>
              <Accordion.Body>
                <CarouselComponent data={ProfessionalProjectsContext} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>People and personal projects</Accordion.Header>
              <Accordion.Body>
                <CarouselComponent data={{...PeopleContext, ...PersonalProjectsContext}} />
              </Accordion.Body>
            </Accordion.Item>


          </Accordion>
        </div>
        );
}
